import * as React from 'react';
import { List, Datagrid, TextField, DateField, SearchInput } from 'react-admin';
import Message from '@material-ui/icons/Message';
import { useState } from 'react';
import { BulkActionButtons, ButtonField } from '../../common';
import MessageDialog from '../../common/Messaging/MessageDialog';
import { LocalDateField } from '../../common/LocalDateField';

export default props => {
  const [deviceForChat, setDeviceForChat] = useState(null);
  return (
    <>
      <List
        {...props}
        filters={[<SearchInput source='q' alwaysOn />]}
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={<BulkActionButtons resource='organisation' />}
        exporter={false}
      >
        <Datagrid rowClick='edit'>
          <TextField source='id' label={'ID'} />
          <TextField source='imei' />
          <TextField source='note' />
          <TextField source='organisationName' />
          <TextField source='crewName' />
          <TextField source='status' />
          <LocalDateField source='lastOnlineDate' showTime emptyText={'N/A'} />
          <DateField source='createDate' />
          <DateField source='updateDate' />
          <ButtonField
            variant={'outlined'}
            onClick={device => {
              setDeviceForChat(device);
            }}
          >
            <Message fontSize={'small'} />
          </ButtonField>
        </Datagrid>
      </List>
      {deviceForChat && (
        <MessageDialog close={() => setDeviceForChat(null)} device={deviceForChat} />
      )}
    </>
  );
};
