import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import * as React from 'react';
import { Alert } from '@mui/material';
import useStyles from './styles';

const CsvInvalidRowsList = ({ data = { message: null, entities: [] } }) => {
  const styles = useStyles();
  return (
    <>
      <Alert severity='warning' className={styles.invalidRowsMessage}>
        {data.message}
      </Alert>
      <br />
      <div className={styles.failedRowsContainer}>
        {data.entities.length > 0 && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Row line</TableCell>
                  <TableCell>Error Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.entities
                  .filter(row => !row.success)
                  .map(row => (
                    <TableRow key={row.index}>
                      <TableCell>{row.index}</TableCell>
                      <TableCell>{row.message}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
};

export default CsvInvalidRowsList;
