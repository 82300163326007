import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  SearchInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { BulkActionButtons } from '../../common';
import { rowColorStyle } from '../../../utils';
import { ListActions } from './ListActions';

export default props => (
  <List
    {...props}
    filters={[
      <SearchInput source='q' alwaysOn />,
      <ReferenceInput source='projectId' fullWidth reference='project' label='Project' allowEmpty>
        <AutocompleteInput optionText='name' />
      </ReferenceInput>,
    ]}
    actions={<ListActions />}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={<BulkActionButtons resource='project' />}
    exporter={false}
  >
    <Datagrid rowClick='edit' rowStyle={rowColorStyle}>
      <TextField source='id' label={'ID'} />
      <TextField source='name' />
      <TextField source='organisationName' />
      <TextField source='projectName' />
      <DateField source='createDate' />
      <DateField source='updateDate' />
    </Datagrid>
  </List>
);
