import * as React from 'react';
import { List, Datagrid, TextField, DateField, SearchInput } from 'react-admin';
import { BulkActionButtons } from '../../common';
import { rowColorStyle } from '../../../utils';
import { crewType } from '../../../constants';
import { ListActions } from './ListActions';

export default props => {
  const crewRowColorStyle = record => {
    if (record.type === crewType.super) {
      return { backgroundColor: 'rgba(255, 120, 0, 0.4)' };
    }
    return rowColorStyle(record);
  };

  return (
    <List
      {...props}
      filters={[<SearchInput source='q' alwaysOn />]}
      actions={<ListActions />}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={<BulkActionButtons resource='crew' />}
      exporter={false}
    >
      <Datagrid rowClick='edit' rowStyle={crewRowColorStyle}>
        <TextField source='id' label={'ID'} />
        <TextField source='name' />
        <TextField source='type' />
        <TextField source='organisationName' />
        <TextField source='projectsCount' />
        <DateField source='createDate' />
        <DateField source='updateDate' />
      </Datagrid>
    </List>
  );
};
