/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { useRecordContext } from 'react-admin';

export const LocalDateField = props => {
  const record = useRecordContext(props);
  const date = record[props.source]
    ? new Date(`${record[props.source].split('.')[0]}Z`).toLocaleString()
    : 'N/A';
  return <span>{date}</span>;
};
