import * as React from 'react';
import { Input, Button } from 'react-chat-elements';
import SendIcon from '@material-ui/icons/Send';
import AttachFile from '@material-ui/icons/AttachFile';
import { useFilePicker } from 'use-file-picker';

export default ({
  disabled = false,
  onSend = () => {},
  onFilesSelected = () => {},
  imagesUploadCount = 0,
}) => {
  const inputReference = React.createRef();

  const maxFilesPerMessage = 5;

  const [openFileSelector] = useFilePicker({
    accept: 'image/*',
    maxFileSize: 10,
    limitFilesConfig: { min: 1, max: maxFilesPerMessage }, // Limit can be increased but in that case performance should be tested.
    onFilesSuccessfulySelected: ({ plainFiles }) => {
      onFilesSelected(plainFiles);
    },
    onFilesRejected: ({ errors }) => {
      if (errors.some(e => e.maxLimitExceeded)) {
        alert(`Maximum of ${maxFilesPerMessage} files allowed.`);
      }
    },
  });

  const sendMessage = () => {
    const text = inputReference.current.value;
    if (!text || !text.trim() || disabled) {
      return;
    }
    inputReference.current.value = '';
    onSend(text);
  };

  const chooseFiles = () => openFileSelector();

  return (
    <>
      <Input
        referance={inputReference}
        placeholder='Write a message...'
        multiline={false}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            sendMessage();
          }
        }}
        autofocus
        leftButtons={
          <>
            <Button
              backgroundColor={'rgba(255,255,255,0)'}
              disabled={disabled}
              color={'#00a7cd'}
              onClick={chooseFiles}
              text={imagesUploadCount ? `${imagesUploadCount} file(s)` : ''}
              icon={{
                float: 'left',
                size: 15,
                component: <AttachFile />,
              }}
            />
          </>
        }
        rightButtons={
          <Button
            backgroundColor={'rgba(255,255,255,0)'}
            color={'#00a7cd'}
            disabled={disabled}
            onClick={sendMessage}
            icon={{
              float: 'left',
              size: 15,
              component: <SendIcon />,
            }}
          />
        }
      />
    </>
  );
};
