import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { ChatItem } from 'react-chat-elements';
import moment from 'moment';
import BasicScrollToBottom from 'react-scroll-to-bottom';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import {
  archiveMessage,
  getMessagesForDevice,
  sendMessageForDevice,
} from '../../../utils/axiosHttpClient';
import useStyles from './styles';
import { longPollingRefreshRateMs, messageDialogLoadingState } from '../../../constants/messaging';

export default ({ close, device }) => {
  const styles = useStyles();
  const [messages, setMessages] = useState([]);
  const [messagesLoadingState, setMessagesLoadingState] = useState(messageDialogLoadingState.idle);
  const [isSendingDisabled, setIsSendingDisabled] = useState(false);
  const [uploadImages, setUploadImages] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if ([messageDialogLoadingState.inProgress].includes(messagesLoadingState)) {
        return;
      }
      getMessagesForDevice(device.id).then(result => {
        const isActive = ![messageDialogLoadingState.idle, messageDialogLoadingState.done].includes(
          messagesLoadingState,
        );
        if (isActive) {
          return;
        }
        setMessages(result.data);
      });
    }, longPollingRefreshRateMs);
    return () => clearInterval(intervalId);
  }, [device.id, messagesLoadingState]);

  const closeWindow = () => {
    if (!device) {
      return;
    }
    close();
  };

  useEffect(() => {
    if (
      [messageDialogLoadingState.inProgress, messageDialogLoadingState.done].includes(
        messagesLoadingState,
      )
    ) {
      return;
    }

    setMessagesLoadingState(messageDialogLoadingState.inProgress);
    getMessagesForDevice(device.id).then(result => {
      setMessages(result.data);
      setMessagesLoadingState(messageDialogLoadingState.done);
    });
  }, [device.id, messagesLoadingState]);

  const minutesSinceOnline = device?.lastOnlineDate
    ? moment.duration(moment().diff(device?.lastOnlineDate)).asMinutes()
    : null;

  const onSend = text => {
    setIsSendingDisabled(true);
    setMessagesLoadingState(messageDialogLoadingState.inProgress);
    sendMessageForDevice(device.id, text, uploadImages)
      .then(() => {
        setMessagesLoadingState(messageDialogLoadingState.refresh);
        setUploadImages([]);
      })
      .finally(() => {
        setIsSendingDisabled(false);
      });
  };

  const onRemoveMessageClick = message => {
    if (window.confirm('Are you sure you want to retract this message?')) {
      setIsSendingDisabled(true);
      setMessagesLoadingState(messageDialogLoadingState.inProgress);
      archiveMessage(message.id)
        .then(() => {
          setMessagesLoadingState(messageDialogLoadingState.refresh);
        })
        .finally(() => {
          setMessagesLoadingState(messageDialogLoadingState.done);
          setIsSendingDisabled(false);
        });
    }
  };

  return (
    <Dialog
      open={!!device}
      onClose={() => {
        closeWindow();
      }}
      fullWidth
      className={styles.messageDialog}
      maxWidth={'xl'}
    >
      <DialogTitle className={styles.messageDialogTitle}>
        {[messageDialogLoadingState.inProgress, messageDialogLoadingState.refresh].includes(
          messagesLoadingState,
        ) && (
          <div className={styles.messagesLoaderContainer}>
            <CircularProgress color='inherit' />
          </div>
        )}
        <ChatItem
          id={device?.id}
          avatar='userpic.png'
          alt={`Device #${device?.id}`}
          title={`Device #${device?.id}`}
          subtitle={`${device?.organisationName} / ${device?.crewName}`}
          date={`${device?.lastOnlineDate?.split('.')[0]}Z`}
          statusColor={minutesSinceOnline > 5 ? null : '#5f5'} // Show as online if device was online in last 5 minutes.
          muted
        />
      </DialogTitle>
      <DialogContent className={styles.messageDialogContent}>
        <BasicScrollToBottom className={styles.messageScrollerContent}>
          <MessageList messages={messages} onRemoveMessageClick={onRemoveMessageClick} />
        </BasicScrollToBottom>
      </DialogContent>
      <DialogActions>
        <MessageInput
          onSend={text => onSend(text)}
          disabled={isSendingDisabled}
          onFilesSelected={files => setUploadImages(files)}
          imagesUploadCount={uploadImages.length}
        />
      </DialogActions>
    </Dialog>
  );
};
