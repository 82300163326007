import * as React from 'react';
import { Edit, SimpleShowLayout, TextField, DateField } from 'react-admin';
import useStyles from './styles';

export default props => {
  const styles = useStyles();

  return (
    <Edit {...props}>
      <SimpleShowLayout>
        <TextField source='id' label={'ID'} />
        <TextField source='text' className={styles.logField} />
        <TextField source='type' />
        <TextField source='deviceId' />
        <TextField source='appVersion' />
        <DateField source='errorDate' />
        <DateField source='createDate' />
      </SimpleShowLayout>
    </Edit>
  );
};
