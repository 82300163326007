import * as React from 'react';
import { MessageList } from 'react-chat-elements';
import ImageGallery from 'react-image-gallery';
import useStyles from './styles';

const deliveryStatuses = {
  Sent: 'waiting',
  PushSent: 'sent',
  Delivered: 'received',
  Read: 'read',
  RemovingAcknowledged: 'removingAcknowledged',
};

export default ({ messages, onRemoveMessageClick = () => {} }) => {
  const styles = useStyles();
  const messageListReference = React.createRef();

  const formattedMessages = [];
  for (const message of messages) {
    const formattedMessage = {
      position: 'left',
      type: 'text',
      id: message.id,
      text: message.text,
      date: Date.parse(`${message.deliveryStatusUpdateDate}Z`),
      removeButton: true,
      retracted: false,
      status: deliveryStatuses[message.deliveryStatus], // Possible values: 'waiting' | 'sent' | 'received' | 'read'
    };
    if (message.attachments && message.attachments.length) {
      const images = [];

      message.attachments.forEach(attachment => {
        const url = `http://localhost:5000/message/attachment/${attachment.fileName}?mediaType=image%2Fjpeg`;
        images.push({
          original: url,
          thumbnail: url,
          thumbnailLoading: 'lazy',
          thumbnailClass: styles.messageImageThumbnail,
        });
      });

      formattedMessage.renderAddCmp = (
        <ImageGallery
          additionalClass={styles.groupImageBlock}
          items={images}
          lazyLoad
          showNav={false}
          showPlayButton={false}
        />
      );
    }
    formattedMessages.push(formattedMessage);
  }

  return (
    <MessageList
      className={styles.messageList}
      referance={messageListReference}
      lockable
      dataSource={formattedMessages}
      onRemoveMessageClick={e => onRemoveMessageClick(e)}
    />
  );
};
