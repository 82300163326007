export const crewType = Object.freeze({
  general: 'General',
  super: 'Super',
});

export const crewTypeFormList = Object.freeze([
  { id: 'General', name: crewType.general },
  {
    id: 'Super',
    name: `${crewType.super} (WARNING: Super Crew members have access to all projects of all organisations)`,
  },
]);
