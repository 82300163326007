import * as React from 'react';
import { List, Datagrid, TextField, DateField, SearchInput } from 'react-admin';
import SelectAll from '@material-ui/icons/SelectAll';
import { BulkActionButtons, ButtonField } from '../../common';
import { rowColorStyle } from '../../../utils';
import { ListActions } from './ListActions';

export default props => (
  <List
    {...props}
    filters={[<SearchInput source='q' alwaysOn />]}
    actions={<ListActions />}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={<BulkActionButtons resource='project' />}
    exporter={false}
  >
    <Datagrid rowClick='edit' rowStyle={rowColorStyle}>
      <TextField source='id' label={'ID'} />
      <TextField source='name' />
      <TextField source='organizeId' label='OrganizeId' />
      <TextField source='organisationName' />
      <TextField source='sectionsCount' />
      <TextField source='crewsCount' />
      <DateField source='createDate' />
      <DateField source='updateDate' />
      <ButtonField
        onClick={record => {
          const { history } = props;
          history.push({
            pathname: '/section',
            search: `?filter=${JSON.stringify({ projectId: record.id })}`,
          });
        }}
      >
        <SelectAll fontSize={'small'} />
      </ButtonField>
    </Datagrid>
  </List>
);
