import * as React from 'react';
import { Button } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { useState } from 'react';
import useStyles from './styles';
import { uploadDialogStatus, uploadTextEntries } from '../../../constants/csvUpload';
import CsvUploadDialog from './CsvUploadDialog';

const CsvUploadActionButton = ({ refresh, resource }) => {
  const styles = useStyles();
  const [dialogStatus, setDialogStatus] = useState(uploadDialogStatus.closed);
  return (
    <>
      <Button
        className={styles.actionPanelButton}
        variant={'text'}
        color={'primary'}
        onClick={() => setDialogStatus(uploadDialogStatus.chooseFile)}
      >
        <PublishIcon />
        &nbsp;
        <span>{uploadTextEntries.import}</span>
      </Button>
      <CsvUploadDialog
        dialogStatus={dialogStatus}
        setDialogStatus={setDialogStatus}
        refresh={refresh}
        resource={resource}
      />
    </>
  );
};

export default CsvUploadActionButton;
