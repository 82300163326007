import Dropzone from 'react-dropzone';
import PublishIcon from '@material-ui/icons/Publish';
import * as React from 'react';
import useStyles from './styles';
import { uploadTextEntries } from '../../../constants/csvUpload';

const CsvUploadDropzone = ({ onDrop = () => {} }) => {
  const styles = useStyles();
  return (
    <>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className={styles.fileDropZone}>
              <input {...getInputProps()} />
              <PublishIcon className={styles.fileDropZoneIcon} />
              <div>{uploadTextEntries.dragNDropFile}</div>
            </div>
          </section>
        )}
      </Dropzone>
    </>
  );
};

export default CsvUploadDropzone;
