export const uploadDialogStatus = {
  closed: 1,
  chooseFile: 2,
  uploadProgress: 3,
  failedUpload: 4,
  successfulUpload: 5,
};

export const uploadDialogTitle = {
  [uploadDialogStatus.closed]: '',
  [uploadDialogStatus.chooseFile]: 'Import from CSV file',
  [uploadDialogStatus.uploadProgress]: 'Uploading is in progress',
  [uploadDialogStatus.failedUpload]: 'CSV uploading failed',
  [uploadDialogStatus.successfulUpload]: 'CSV uploading was successful',
};

export const uploadTextEntries = {
  import: 'IMPORT',
  dragNDropFile: "Drag 'n' drop a valid CSV file here, or click to select it",
  close: 'Close',
  retry: 'Retry',
};
