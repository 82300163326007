import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  actionPanelButton: {
    padding: '3px 5px',
    fontSize: '0.8125rem',
  },
  fileDropZone: {
    border: '8px dashed #aaa',
    borderRadius: 15,
    height: '150px',
    maxWidth: '200px',
    textAlign: 'center',
    padding: '30px',
    cursor: 'pointer',
    color: '#aaa',
  },
  fileDropZoneIcon: {
    fontSize: 100,
  },
  loaderContainer: {
    display: 'flex',
    height: 80,
  },
  loader: {
    margin: 'auto',
    alignItems: 'center',
    alignSelf: 'center',
  },
  successIconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  successUploadIcon: {
    fontSize: 100,
    color: '#10c5f8',
  },
  invalidRowsMessage: {
    minWidth: '500px',
  },
  failedRowsContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
  },
});

export default useStyles;
