import { CreateButton, TopToolbar, useRefresh } from 'react-admin';
import * as React from 'react';
import CsvUploadActionButton from '../../common/CsvUploadActionButton';

export const ListActions = props => {
  const refresh = useRefresh();
  const { className, basePath, resource } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <CsvUploadActionButton refresh={refresh} resource={resource} />
    </TopToolbar>
  );
};
