import axios from 'axios';
import { getApiUrl } from './urlHelper';

const axiosInstance = axios.create({
  baseURL: getApiUrl(),
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');

    // This is expected behaviour for the axios library.
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

// Upload file to batch creation endpoint.
// We're using axios instead because default ReactAdmin httpClient has pretty limited functionality in this case.
export const batchCreateFromCsvFileRequest = (file, resource) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axiosInstance({
    method: 'POST',
    url: `/${resource}/batch`,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

// Load sent messages list for device.
export const getMessagesForDevice = deviceId => {
  return axiosInstance({
    method: 'GET',
    url: `/message?deviceId=${deviceId}`,
  });
};

// Send message (text and/or up to 5 images) to device.
export const sendMessageForDevice = (deviceId, message, attachments = []) => {
  const bodyFormData = new FormData();
  bodyFormData.append('model.message', message);
  attachments.forEach(attachment => {
    bodyFormData.append('model.attachments', attachment);
  });
  return axiosInstance({
    method: 'POST',
    url: `/message/device/${deviceId}`,
    data: bodyFormData,
  });
};

// Remove message.
export const archiveMessage = messageId => {
  return axiosInstance({
    method: 'POST',
    url: `/message/archive`,
    data: [messageId],
  });
};
