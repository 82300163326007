import * as React from 'react';
import { List, Datagrid, TextField, DateField, SearchInput } from 'react-admin';
import { Settings } from '@material-ui/icons';
import { BulkActionButtons, ButtonField } from '../../common';
import OrganisationOtpField from './OrganisationOtpField';
import { rowColorStyle } from '../../../utils';

export default props => {
  return (
    <List
      {...props}
      filters={[<SearchInput source='q' alwaysOn />]}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={<BulkActionButtons resource='organisation' />}
      exporter={false}
    >
      <Datagrid rowClick='edit' rowStyle={rowColorStyle}>
        <TextField source='id' label={'ID'} />
        <TextField source='name' />
        <TextField source='organizeId' label='OrganizeId' />
        <TextField source='crewsCount' />
        <TextField source='devicesCount' />
        <TextField source='projectsCount' />
        <DateField source='createDate' />
        <DateField source='updateDate' />
        <ButtonField
          variant={'outlined'}
          title={'Organisation-specific App Settings'}
          onClick={record => {
            const { history } = props;
            history.push({
              pathname: `/appConfig/${record.id}`,
            });
          }}
        >
          <Settings fontSize={'small'} />
        </ButtonField>
        <OrganisationOtpField />
      </Datagrid>
    </List>
  );
};
