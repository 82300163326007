import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as React from 'react';
import { useState } from 'react';
import {
  uploadDialogStatus,
  uploadDialogTitle,
  uploadTextEntries,
} from '../../../constants/csvUpload';
import CsvUploadDropzone from './CsvUploadDropzone';
import CsvInvalidRowsList from './CsvInvalidRowsList';
import useStyles from './styles';
import { batchCreateFromCsvFileRequest } from '../../../utils/axiosHttpClient';

const CsvUploadDialog = ({ dialogStatus, setDialogStatus, refresh, resource }) => {
  const styles = useStyles();

  const [uploadError, setUploadError] = useState(null); // Structure: { message: null, entities: [] }
  const performUpload = async file => {
    setDialogStatus(uploadDialogStatus.uploadProgress);

    try {
      const response = await batchCreateFromCsvFileRequest(file[0], resource); // Only one file is supported.

      if (![200, 201].includes(response?.data?.statusCode)) {
        setUploadError({
          message: response?.data?.message || 'Internal error',
          entities:
            response?.data?.result.map((r, index) => ({
              message: r.message,
              success: r.success,
              index: index + 1,
            })) || [],
        });
        setDialogStatus(uploadDialogStatus.failedUpload);
        return;
      }
      refresh();
      setDialogStatus(uploadDialogStatus.successfulUpload);
    } catch (exception) {
      const errorMessage = exception?.body?.message || 'Internal error';
      setUploadError({ message: errorMessage, entities: [] });
      setDialogStatus(uploadDialogStatus.failedUpload);
    }
  };

  return (
    <Dialog
      open={dialogStatus !== uploadDialogStatus.closed}
      onClose={() =>
        dialogStatus !== uploadDialogStatus.uploadProgress &&
        setDialogStatus(uploadDialogStatus.closed)
      }
    >
      <DialogTitle>{uploadDialogTitle[dialogStatus]}</DialogTitle>
      <DialogContent>
        {dialogStatus === uploadDialogStatus.chooseFile && (
          <CsvUploadDropzone onDrop={file => performUpload(file)} />
        )}
        {dialogStatus === uploadDialogStatus.uploadProgress && (
          <div className={styles.loaderContainer}>
            <CircularProgress color='inherit' className={styles.loader} />
          </div>
        )}
        {dialogStatus === uploadDialogStatus.failedUpload && (
          <CsvInvalidRowsList data={uploadError} />
        )}
        {dialogStatus === uploadDialogStatus.successfulUpload && (
          <div className={styles.successIconContainer}>
            <CheckCircleIcon className={styles.successUploadIcon} />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {dialogStatus === uploadDialogStatus.failedUpload && (
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => setDialogStatus(uploadDialogStatus.chooseFile)}
          >
            {uploadTextEntries.retry}
          </Button>
        )}
        {[uploadDialogStatus.successfulUpload, uploadDialogStatus.failedUpload].includes(
          dialogStatus,
        ) && (
          <Button variant={'outlined'} onClick={() => setDialogStatus(uploadDialogStatus.closed)}>
            {uploadTextEntries.close}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CsvUploadDialog;
