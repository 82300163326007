import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {},
    activeChatItem: {},
    top: {},
    center: {},
    bottom: {},
    messageDialog: {
      position: 'relative',
    },
    messageDialogTitle: {},
    messageDialogContent: {
      backgroundColor: 'rgba(50, 167, 205, 0.2)',
      padding: 0,
    },
    messageScrollerContent: {
      height: '900px',
      width: '100%',
    },
    messageList: {
      minWidth: '800px',
      margin: '20px',
    },
    messagesLoaderContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 10000000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    groupImageBlock: {
      width: '300px',
      backgroundColor: '#fff',
      padding: '5px',
      borderRadius: '5px',
      boxShadow: '1px 1px 1px 1px #0003',
    },
    messageImageThumbnail: {
      width: '60px',
      height: '50px',
      overflow: 'hidden',
    },
  }),
);

export default useStyles;
